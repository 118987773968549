import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Option, Tooltip } from 'app-wrapper/view/components';
import { ICountryListDTM } from 'app-wrapper/models/dtm';

import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  CustomerAddInternalStateContainer,
} from 'user-management/view/pages/Account/components/AccountDrawer/components/CustomerAddInternal/components/CustomerAddInternalContactInformation/components/CustomerAddInternalState.container';
import {
  CAITitle, CAISpaceColumn, CAISpaceInput, CAISpaceRow, CAIHeader, CAIInput, CAIAddress, CAISelect, CAISubTitle,
} from './CustomerAddInternalContactInformation.styled';

interface ICustomerAddInternalGeneralInformationComponentProps {
  organization?: OrganizationDTM
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  countriesList?: ICountryListDTM[]
  onChangeUpdateAddress1: (address1: string) => void
  onChangeUpdateAddress2: (address2: string) => void
  onChangeUpdateAddressCity: (city: string) => void
  onChangeUpdateAddressPostalCode: (postalCode: string) => void
  onChangeUpdateAddressCountry: (country: string) => void
  onClearUpdateAddressCountry: () => void
}

export const CustomerAddInternalContactInformationComponent: FC<ICustomerAddInternalGeneralInformationComponentProps> = ({
  organization,
  organizationFieldsErrors,
  countriesList,
  onChangeUpdateAddress1,
  onChangeUpdateAddress2,
  onChangeUpdateAddressCity,
  onChangeUpdateAddressPostalCode,
  onChangeUpdateAddressCountry,
  onClearUpdateAddressCountry,
}) => {
  const { t } = useTranslation();

  const onChangeAddress1 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddress1(event.target.value);
  }, []);

  const onChangeAddress2 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddress2(event.target.value);
  }, []);

  const onChangeAddressCity = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddressCity(event.target.value);
  }, []);

  const onChangeAddressPostalCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddressPostalCode(event.target.value);
  }, []);

  const onChangeAddressCountry = useCallback((country: string) => {
    onChangeUpdateAddressCountry(country);
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.message),
    [],
  );

  return (
    <CAIAddress>
      <CAIHeader>
        {t('address')}
      </CAIHeader>
      <CAITitle>
        {`${t('Address Line 1')} *`}
      </CAITitle>
      <CAIInput
        data-class="CAIInputAddress1"
        value={organization?.address?.address1}
        hasError={getHasErrorField(organizationFieldsErrors?.address1)}
        onChange={onChangeAddress1}
      />
      <CAISpaceInput />
      <CAITitle>
        {`${t('Address Line 2')}`}
      </CAITitle>
      <CAIInput
        data-class="CAIInputAddress2"
        value={organization?.address?.address2}
        hasError={getHasErrorField(organizationFieldsErrors?.address2)}
        onChange={onChangeAddress2}
      />
      <CAISpaceInput />
      <CAISpaceRow>
        <CAISpaceColumn widthProp="50%">
          <CAITitle>
            {`${t('Country')} *`}
            <Tooltip title={t('At this moment, we can only support countries from the list. More countries will be added during 2024. We apologize for any inconvenience.')} placement="top">
              <InfoCircleOutlined />
            </Tooltip>
          </CAITitle>
          <CAISelect
            data-class="CAISelectCountry"
            value={organization?.address?.country || undefined}
            hasError={getHasErrorField(organizationFieldsErrors?.addressCountry)}
            showSearch
            allowClear={!!organization?.address.country}
            onChange={onChangeAddressCountry}
            onClear={onClearUpdateAddressCountry}
            placeholder={t('None')}
            optionFilterProp="children"
          >
            {countriesList?.map((item) => (
              <Option
                value={item.code}
                key={`CAISelect_${item.code}`}
              >
                {item.name}
              </Option>
            ))}

          </CAISelect>
        </CAISpaceColumn>
        <CAISpaceColumn widthProp="50%">
          <CAITitle>
            {`${t('State')}`}
            <CAISubTitle>{`${t('USRequired')}`}</CAISubTitle>
          </CAITitle>
          <CustomerAddInternalStateContainer />
        </CAISpaceColumn>
      </CAISpaceRow>
      <CAISpaceInput />
      <CAISpaceRow>
        <CAISpaceColumn widthProp="50%">
          <CAITitle>
            {`${t('City')} *`}
          </CAITitle>
          <CAIInput
            data-class="CAIInputCity"
            value={organization?.address?.city}
            hasError={getHasErrorField(organizationFieldsErrors?.addressCity)}
            onChange={onChangeAddressCity}
          />
        </CAISpaceColumn>
        <CAISpaceColumn widthProp="50%">
          <CAITitle>
            {`${t('PostalCode')} *`}
          </CAITitle>
          <CAIInput
            data-class="CAIInputPostalCode"
            value={organization?.address?.postalCode}
            hasError={getHasErrorField(organizationFieldsErrors?.addressPostalCode)}
            onChange={onChangeAddressPostalCode}
          />
        </CAISpaceColumn>
      </CAISpaceRow>
    </CAIAddress>
  );
};
