import { BodyTextRegular } from 'app-wrapper/view/fonts';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography';

export const Warning = styled.div.attrs(
  (props: {
    type?: 'info' | 'warning',
  }) => ({
    type: props.type,
  }),
)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  height: fit-content;
  background-color: ${({ type, theme }) => (type === 'info' ? theme?.themesColors?.primaryBranding1 : 'rgba(255, 251, 230, 1)')};
  border: 1px solid ${({ type, theme }) => (type === 'info' ? theme?.themesColors?.primaryBranding3 : 'rgba(255, 229, 143, 1)')};
`;

export const WarningIcon = styled(ExclamationCircleOutlined)`
  margin-top: 2px;
  margin-bottom: auto;
  color: rgba(250, 173, 20, 1);
  font-size: 18px;
`;

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const WarningTitle = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;

export const WarningText = styled(Typography)`
  ${BodyTextRegular}
`;
