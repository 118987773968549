import React, {
  FC, useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  TeamOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { RouteNames } from 'app-wrapper/constants';
import { hasAccess } from 'app-wrapper/utils';
import { COUNTRIES_SELECT_OPTIONS } from 'app-wrapper/repository/store/CountriesList/countries';
import { ShippingPartyDTM, ShippingPartyReference } from 'shipment-operations/models/dtm';
import { EditButton } from 'app-wrapper/view/components';
import {
  MissingTextValue,
  RowSpaceBetween,
  FlexCol,
  GapVerticalContainerStyled, ContentSectionContainer, InfoCardComponent, GapHorizontalContainerStyled,
} from 'app-wrapper/view/guideline';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  Wrap,
  Content,
  ContentRow,
  Title,
  Divider,
  ReferencesBlock,
  Text,
  TextValue,
  Tag,
  // ArrowWrap,
  // CollapseWrap,
} from './ShippingPartySection.styled';

const VISIBLE_REFERENCES_LIMIT = 2;

interface IShippingPartySectionProps {
  shippingParty: ShippingPartyDTM;
  title: string;
  addressTitle: string;
  editSupplierAndReceiverPermissions?: PermissionAttributePolicy
}

export const ShippingPartySection: FC<IShippingPartySectionProps> = ({
  shippingParty,
  title,
  addressTitle,
  editSupplierAndReceiverPermissions,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    company,
    address,
    contact,
    references,
  } = shippingParty;
  const validReferences = references.filter(({ value }) => !!value.length);

  const country = COUNTRIES_SELECT_OPTIONS?.find(({ value }) => value === address?.country);

  const [hiddenReferences, visibleReferences] = useMemo(() => {
    const hidden: ShippingPartyReference[] = [...validReferences];
    const visible: ShippingPartyReference[] = hidden.splice(0, VISIBLE_REFERENCES_LIMIT);

    return [hidden, visible];
  }, [validReferences]);

  const isAbleToEdit = hasAccess(editSupplierAndReceiverPermissions, PermissionAttributePolicy.WRITE);

  const handleEdit = useCallback(() => {
    if (shipmentId) {
      navigate(RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId));
    }
  }, [editSupplierAndReceiverPermissions, shipmentId]);

  // const toggleCollapse = useCallback(() => setIsCollapsed((_isCollapsed) => !_isCollapsed), [setIsCollapsed]);

  return (
    <Wrap>
      <GapVerticalContainerStyled>
        <RowSpaceBetween>
          <Title>
            {title}
          </Title>

          {isAbleToEdit ? (
            <EditButton onClick={handleEdit} />
          ) : null}
        </RowSpaceBetween>

        <Content>
          <ContentRow>
            {/* <ArrowWrap onClick={toggleCollapse}> */}
            {/*  <VerticalFormItemSvg */}
            {/*    style={{ */}
            {/*      color: isCollapsed ? themesColors?.primaryBranding6 : themesColors?.characterBrandingTitle85, */}
            {/*    }} */}
            {/*    rotate={isCollapsed ? 0 : 270} */}
            {/*  /> */}
            {/* </ArrowWrap> */}

            <ContentSectionContainer>
              <InfoCardComponent
                icon={<TeamOutlined />}
                title={t('Company Name')}
                value={company?.name || <MissingTextValue />}
              />
            </ContentSectionContainer>

            <ContentSectionContainer>
              <InfoCardComponent
                icon={<EnvironmentOutlined />}
                title={addressTitle}
                value={address ? (
                  <>
                    {address.address1} {address.address2 ? `, ${address.address2}` : ''}<br />
                    {`${address.city}, ${address.state}, ${address.postalCode}`} <br />
                    {country?.label || address.country}
                  </>
                ) : <MissingTextValue />}
              />
            </ContentSectionContainer>

            <ContentSectionContainer>
              <InfoCardComponent
                icon={<UserOutlined />}
                title={t('Contact Person')}
                value={contact ? (
                  <>
                    {contact?.fullName}<br />
                    {contact?.email}<br />
                    {contact?.phone}
                  </>
                ) : <MissingTextValue />}
              />
            </ContentSectionContainer>

            <Divider
              type="vertical"
              dashed
            />

            <ContentSectionContainer>
              <ReferencesBlock>
                <FlexCol>
                  <Text>{t('References')}</Text>

                  {validReferences.length ? (
                    <GapHorizontalContainerStyled>
                      {visibleReferences.map((reference) => (
                        <Tag key={reference.id}>
                          {reference.value}
                        </Tag>
                      ))}

                      {hiddenReferences.length ? (
                        <Tag>
                          + {hiddenReferences.length}
                        </Tag>
                      ) : null}
                    </GapHorizontalContainerStyled>
                  ) : <MissingTextValue />}
                </FlexCol>

                <FlexCol>
                  <Text>{t('Tax ID')}</Text>

                  {company?.taxId ? (
                    <TextValue>
                      {company.taxId}
                    </TextValue>
                  ) : <MissingTextValue />}
                </FlexCol>
              </ReferencesBlock>
            </ContentSectionContainer>
          </ContentRow>

          {/* <CollapseWrap> */}
          {/*  <Collapse */}
          {/*    activeKey={isCollapsed ? '1' : ''} */}
          {/*  > */}
          {/*    <Panel header="" key="1" extra={null}> */}
          {/*      collapse value */}
          {/*    </Panel> */}
          {/*  </Collapse> */}
          {/* </CollapseWrap> */}
        </Content>
      </GapVerticalContainerStyled>
    </Wrap>
  );
};
